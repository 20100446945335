<template>
  <v-container v-if="getSelectedEmployee && getSelectedEmployee.user" fluid>
    <v-row>
      <v-col cols="12" md="6" class="d-flex align-center justify-start">
        <app-bar-return />
        <div>
          <new-app-bar-route
            :title="$helpers.getEmployeeName(getSelectedEmployee)"
            :subtitle="$trans('section_admin_panel_employees')"
          />
          <div>
            <v-chip
              small
              :color="
                $helpers.getStatusColor(getSelectedEmployee.user.status).color
              "
              :class="
                $helpers.getStatusColor(getSelectedEmployee.user.status).class
              "
            >
              <v-icon left>$check</v-icon>
              {{
                isEmployeeActive
                  ? $trans("active_account")
                  : $trans("inactive_account")
              }}
            </v-chip>
          </div>
          <div v-if="$vuetify.breakpoint.smAndUp" class="ml-2">
            <span class="text-caption light--text mr-2">
              {{ $trans("created_at") }}:
              {{
                getSelectedEmployee.user.created_at
                  | dateTime($helpers.hourDayMonthAndYearDate)
              }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-start justify-md-end align-center"
      >
        <employee-app-bar-menu class="float-right" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import NewAppBarRoute from "@/views/dashboard/components/AppBar/AppBarComponents/NewAppBarRoute.vue";

export default {
  name: "EmployeeAppBar",
  components: {
    NewAppBarRoute,
    EmployeeAppBarMenu: () => import("../AppBarComponents/EmployeeAppBarMenu"),
    AppBarReturn: () => import("../AppBarComponents/AppBarReturn"),
  },
  computed: {
    ...mapGetters({
      getSelectedEmployee: "employee/getSelectedEmployee",
    }),
    isEmployeeActive() {
      return (
        this.getSelectedEmployee &&
        this.getSelectedEmployee.user.status === this.$helpers.userStatus.ACTIVE
      );
    },
  },
};
</script>
